/*********************tile-style**************/
.tile-style {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    flex-direction: column;
}
.tile-header {
    text-align: right;
    color: #FFF;
    margin: 0 15px;
    padding: 0;
    position: relative;
    background: transparent;
    box-shadow: none;
}
.tile-icon-up {
    float: left;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
    background-color: #999;
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(255, 152, 0,.4);
}
span.bootstrap-icons {
    width: 56px;
    height: 56px;
    overflow: unset;
    font-size: 36px;
    text-align: center;
    line-height: 56px;
    margin-bottom: 1px;
}
p.tile-para {
    color: #999;
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 0;
}
h3.tile-data-value {
    margin: 0 !important;
    color: #3C4858;
    margin-top: 0px;
    min-height: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 3px;
    text-decoration: none;
    white-space: pre-line;
}
.tile-footer {
    border: 0;
    margin: 0 15px 10px;
    display: flex;
    padding: 0;
    align-items: center;
    padding-top: 10px;
    border-radius: 0;
    justify-content: space-between;
    background-color: transparent;
    border-top: 1px solid #eee;
    margin-top: 20px;
}
.footer-data {
    color: #999;
    display: inline-flex;
    font-size: 12px;
    line-height: 22px;
}
.footer-icon {
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
    color: #f44336;
    margin-right: 5px;
}
.footer-icon svg {
    top: 0px;
    width: 16px;
    height: 16px;
    position: relative;
    margin-left: 3px;
    margin-right: 3px;
    color: red;
}
button.footer-link {
    background: transparent;
    border: none;
    color: #aaa;
    text-decoration: none;
}
.tile-icon-up2 {
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
    background-color: #999;
    background: linear-gradient(60deg, #056916, #008e0e);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(8, 130, 12, 0.4);
}
.tile-body {
    padding: 0px 15px;
}
.chart-container h3 {
    text-align: left;
    color: #aaa;
}
.close-aside {
    display: none;
}
/*********************tile-style**************/