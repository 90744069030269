.select-language {
    width: 20%;
    margin-left: 2%;
}

/* table */
.campus-header{
    background: rgb(214, 231, 244);
    cursor: pointer;
}
.career-card{
    margin: 12px;
    box-shadow: 0 4px 8px 0 rgba(184, 176, 176, 0.2), 0 6px 20px 0 rgba(99, 94, 94, 0.19);
}
.table-card .datatable-header, .card-table .datatable-panel {
    all:initial;
    margin-top: 0;
}
/* csv-upload */
  
.csv-modal .modal-content{
    min-height: fit-content ;
    max-height: 700px;
}
  
.csv-modal-body{
    overflow: scroll;
}

.csv-file-button{
    justify-content: flex-end;
    margin-right: 20px;
  }

/* image-logo */
.image-logo{
    width: 50px;
    height: 50px;
    border-radius: 60%;
}
.form-image-logo{
    position: absolute;
    top: 0;
    left: 0;
    width: 65px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: none;
}
/* search */

.search-select {
    width: 40%;
}

.sort-icon {
    cursor: pointer;
    margin-left: 4px;
}

.campus-url {
    word-break: break-all;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.icons {
    align-self: flex-end;
}

table.campus-table {
    table-layout: fixed;
    word-wrap: break-word;
}