@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
/**************nav-dropdown user*******************/
div#navbar-collapse {
    display: flex;
    position: absolute;
    right: 0px;
}
div#navbar-collapse div {
    padding: 0 10px;
    cursor: pointer;
}
button.dropdown-toggle.btn.btn-secondary {
    background: transparent;
    border: 0;
    color: #000;
    outline: none !important;
}
button.dropdown-toggle.btn.btn-secondary:focus {
    box-shadow: none;
    outline: none !important;
}
.navbar-right .dropdown-menu {
    right: 0 !important;
    left: auto !important;
  }
/**************nav-dropdown user*******************/
/*************************default bootstrap template*******************/

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Droid Sans', sans-serif;
    outline: none;
  }
  ::-webkit-scrollbar {
    background: transparent;
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  body {background-color: #eee;}
  
  #contents {
    position: relative;
    -webkit-transition: .3s;
    transition: .3s;
    margin-left: 290px;
    background-color: #eee;
    padding: 0px 15px;
    min-height: 100vh;
  }
  .navbar{
    position: fixed;
    display: flex;
    padding: .5rem 1rem;
    height: 60px;
    width: 78%;
    top: 0;
    background: #eeeeee;
    box-shadow: 5px 4px 6px #ccc;
    z-index: 9;
  }
  .width-100{
    width: 99%;
  }
  .margin {
    margin-left: 0 !important;
  } 
  .navbar-brand{
    text-transform: capitalize;
    font-weight: bold;
  }
  .component-content{
    margin-top: 90px !important;
  }
 .ics-badge, .ics-career-badge{
   background-color:#118AFF;
    border-radius: 10px;
    color:#fff;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    margin: 5px;
 }
 .ics-career-badge{
    background-color: transparent;
 }
 .ics-career-badge.red{
    background-color: red;
 }
 .ics-career-badge.yellow{
    background-color: yellow;
    color: #000;
 }
 .ics-career-badge.green{
    background-color: green;
 }
 .ics-career-badge.blue{
    background-color: blue;
 }
 .align-item{
  align-content: center;
  justify-content: flex-end;
  text-align: center;
  margin-top: 20px;
 }
 .berkman-test{
   background: #118AFF;
   color: #fff;
    padding: 5px 10px;
    margin-bottom: 2px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
 }
 .berkman-test span:first-child{
   width: 50%;
    text-align: left;
    border-right: 2px solid #fff;
 }
 .berkman-test span:last-child{
  width: 50%;
    text-align: right;
 }
 .custom-fa-check{
  margin-top: 10px;
  zoom: 2;
  cursor: pointer;
 }
  @media (max-width: 767px) {
    .navbar>.container-fluid .navbar-brand {
      margin-left: 15px !important;
    }
    .navbar-default .navbar-nav>li>a {
      padding-left: 0 !important;
    }
    .navbar-nav {
      margin: 0 !important;
    }
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
      border: none !important;
    }
  }

  .dropdown-menu>li>a {
    padding-top: 5px !important;
    padding-right: 5px !important;
  }
  .navbar-default .navbar-nav>li>a>i {
    font-size: 18px !important;
  }
  ul.categories> li {
      border-bottom: 1px solid #ffffff !important;
  }
  .side-nav .categories li {
      text-align: left;
  }
  .side-nav .categories > li > svg {
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
      margin-right: 15px;
      fill: #fff;
      width: 1em;
      height: 1em;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
      float: left;
      position: relative;
      top: 2px;
  }
  .side-nav .categories > li > a i {
      margin-right: 15px;
  }
  .navbar-nav>li.user-profile-dropdown .dropdown-menu {
      left: unset !important;
      right: 0 !important;
  }
  /* Start media query */
  
  @media (max-width: 767px) {
    #contents {
      margin: 0 !important
    }
    .statistics .box {
      margin-bottom: 25px !important;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
      color: #CCC !important
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
      color: #FFF !important
    }
    .navbar-default .navbar-toggle{
      border:none !important;
      color: #EEE !important;
      font-size: 18px !important;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {background-color: transparent !important
    }
   
  }  
  /* Start media query */
  /*************************default bootstrap template*******************/
  
  /*********************nav-bar-fix***************/
  
  .navbar-header {
    position: absolute;
    left: 0;
  }
  .navbar-nav>li {
    float: left;
  }
  /*********************nav-bar-fix***************/
  
  /*********************input-box animation***************/
  .form {
    width: 100%;
    position: relative;
    height: 70px;
    overflow: hidden;
  }
  .form input, .form select, .form textarea {  
      width: 100%;
      height: 100%;
      color: #000;
      padding-top: 20px;
      border: none;
      border-bottom: 1px solid #aaa;
  }
  .form textarea{
    padding-top: 40px;
  }
  .form label {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid #848181;
    margin-bottom: 0px;
    color: #aaa;
  }
  .form label::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #118AFF;
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }
  .content-name {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-bottom: 5px;
    transition: all 0.3s ease;
  }
  .form input:focus, .form select:focus, .form textarea:focus {
    outline: none;
  }
  .form input:focus + .label-name .content-name,
  .form input:valid + .label-name .content-name,
  .form select:focus + .label-name .content-name,
  .form select:valid + .label-name .content-name,
  .form select:checked + .label-name .content-name
  .form textarea:focus + .label-name .content-name,
  .form textarea:valid + .label-name .content-name {
    transform: translateY(-150%);
    font-size: 12px;
    left: 0px;
    color: #118AFF;
  }
  .form input:focus + .label-name::after,
  .form input:valid + .label-name::after,
  .form select:focus + .label-name::after,
  .form select:valid + .label-name::after,
  .form textarea:focus + .label-name::after,
  .form textarea:valid + .label-name::after {
    transform: translateX(0%);
  }
  select + label {
    opacity: 0
  }
  select:focus + label, select:valid + label, select:checked + label {
    opacity: 1
  }
  /*********************input-box animation***************/
  /**************************login-page and change-password *************/
  #contents ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
    height: 0px;
  }
  button.form-control {
    color: #133B84;
    border: none;
    cursor: pointer;
    margin: 15px 0px;
    padding: 12px 30px;
    position: relative;
    font-size: 12px;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
    box-shadow: 0 2px 2px 0 rgba(228, 199, 106, 0.14), 0 3px 1px -2px rgba(219, 203, 52, 0.2), 0 1px 5px 0 rgba(176, 155, 39, 0.12);
    background: linear-gradient(203deg,#FCD56A, #FFC118);
    height: auto;
    border-radius: 4px;
    display: inline-block;
    width: auto;
    font-weight: 600;
    letter-spacing: 1px;
  }
  button.form-control:hover {
    box-shadow: 0 14px 26px -12px rgba(176, 174, 39, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(174, 176, 39, 0.2);
    background: linear-gradient(203deg,#FCD56A, #FFC118);
    color: #133B84;
  }
  #ics-login-container{
    background-color: #eeeeee;
    width: 100%;
    height: 100vh;
  }
  #error-msg{
    color: red;
  }
  .login-page, .change-password-page {
    width: 390px !important;
    overflow: hidden;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 45px;
  }
  .login-page h4.datatable-title, .change-password-page h4.datatable-title{
    text-align: center;
  }
  .login-page button.form-control, .change-password-page button.form-control{
    margin: 10px auto;
    display: table;
  }
  p.remember-me {
    padding: 15px 0px 0px 0px;
    display: table;
    line-height: 15px;
    float: left;
  }
  p.remember-me input[type="checkbox"] {
    float: left;
    margin-right: 10px;
  }
  p.remember-me span {
    float: left;
    color: #aaa;
  }
  .login-page .datatable-container, .change-password-page .datatable-container {
    padding: 0px 20px 0px;
  }
  p.forget-password {
    padding: 15px 0px 0px 0px;
    display: table;
    line-height: 15px;
    float: right;
  }
  p.forget-password a {
    text-decoration: none;
    /* color: #aaa; */
  }
  .login-page .form input, .change-password-page .form input {
    padding-left: 30px;
  }
  .login-page span.content-name, .change-password-page span.content-name {
    left: 30px;
  }
  .login-page .form i, .change-password-page .form i {
    position: absolute;
    bottom: 7px;
    left: 5px;
    font-size: 20px;
    color: #aaaa;
  }
  .login-page .form input:focus + .label-name + i,
  .change-password-page .form input:focus + .label-name + i {
    color: #118AFF;
  }
  @media (max-width: 767px) {
    #ics-login-container{
      height: 100vh;
    }
  }
  /**************************login-page and change-password *************/
  /*********************panel design and header**************************/
  .datatable-panel {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    position: relative;
    font-size: .875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
    display: table;
    width: 100%;
  }
  .datatable-header {
    margin: 0 15px;
    background: linear-gradient(203deg,#0082FF, #133B84);
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4);
    padding: 5px 15px;
    border-radius: 3px;
    color: #ffff;
    font-weight: bold;
    position: relative;
    top: -20px;
  }
  h4.datatable-title {
    color: #FFFFFF;
    margin-top: 0px;
    min-height: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 3px;
    text-decoration: none;
  }
  p.datatable-subtitle {
    color: rgba(255,255,255,.62);
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .datatable-container {
    flex: 1 1 auto;
    padding: 0 20px;
    position: relative;
    -webkit-box-flex: 1;
  }
  .datatable-maindiv {
    width: 100%;
    margin-top: 0px;
    overflow-x: hidden;
  }
  table.datatable-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: transparent;
    display: table;
  }
  table.datatable-table thead {
    color: #133B84;
    display: table-header-group;
  }
  table.datatable-table th, table.datatable-table td {
    padding: 5px 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.42857143;
    vertical-align: middle;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    font-size: 13px;
  }
  table.datatable-table tr {
    color: inherit;
    height: 56px;
    display: table-row;
    outline: none;
    vertical-align: middle;
  }
  table.datatable-table th {
    font-size: 15px !important;
  }
  div#navbar-collapse ul.nav.navbar-nav {
    display: flow-root;
  }
  /*********************panel design and header**************************/
  /*****add-more***************/
  .component-content button.btn.btn-danger.add-more {
    position: absolute;
    z-index: 1;
    right: 30px;
    background: #fff;
    border: #ffff;
    background: #FFC118;
    color: #133B84;
    font-weight: bold;
}
.component-content{
    position: relative;
}
  /*****add-more***************/
  /**********datatable icons*********/
  table.datatable-table td i {
    padding: 10px;
    font-size: 18px;
    margin-right: 5px;
}
  /**********datatable icons*********/
  /************pagination************/
  .custom-pagination {
    display: flex;
    flex-direction: row;
    justify-content:flex-end ;
  }
  ul.pagination {
    padding: 10px 0px;
    float: right;
    margin-bottom: 0px;
}
.total-count {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-weight: 500;
margin-right: 10px;
color: #0082FF;
}
.pagination-select{
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  margin-left: 10px;
  align-items: center;
}
.pagination-select select{
  color: #ffffff;
  padding: 8px;
  background-color: #0082FF;

}
/**************pagination************/
select {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #aaa;
}
div#navbar-collapse button.dropdown-item:active {
    background: transparent;
    outline: 0;
}
div#navbar-collapse button.dropdown-item a:hover {
    text-decoration: none;
}

/**************collapsed************/

  .expand-button {
      position: relative;
  }
  .accordion-toggle .expand-button::after
  {
    position: absolute;
    left:.75rem;
    top: 50%;
    transform: translate(0, -50%);
    content: '-';
    font-size: 20px;
    cursor: pointer;
  }
  .accordion-toggle.shrink .expand-button::after
  {
    content: '+';
    font-size: 20px;
    cursor: pointer;
  }
  
  /*User page*/
  .show-blocks{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }
  .show-blocks > div, .test-type{
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: white;
  }
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #118AFF;
    border: 1px double #118AFF;
    color: #fff;
}
  .CalendarDay__selected_span {
    background: #118AFF;
    border: 1px double #118AFF ;
    color: #fff;
}

.quill-editor .ql-editor {
  min-height: 100px;
}
.event-popup > .modal-content {
  width: 120%;
}

.career-details-modal > .modal-content {
  width: 180%;
  left: -100px;
}

.btn-container{
  position: absolute;
    right: 0px;
    width: 30%;
    z-index: 1;
}
.segment-option{
  width: 50%;
  height: 40px;
  margin-right: 1rem;
  padding: 0 10px;
  border-radius: 8px;
}
.csv-file-button{
  justify-content: flex-end;
  margin-right: 20px;
}

/* button:focus */
button:focus{
  outline: none;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
}
button[disabled].form-control {
  cursor: not-allowed;
  color:#133B84;
}

.voucher-date-input .DateInput.DateInput_1 {
  width: 210px;
}

fieldset{
  border: 1px solid #ccc;
  margin-top: 1rem;
}
fieldset > legend{
  font-size: 0.8rem;
  color: #848383;
  width:auto;
  margin-bottom: 0;
}