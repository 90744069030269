.spinner {
    z-index: 1004;
    position: fixed;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
  }

  .overlay {
    background: #e9e9e9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .center-block {
    width: 60px;
    height: 60px;
  }
  
  .spinner .center-block {
    position: absolute;
    top: 50%;
    left: 55%;
    margin-left: -30px;
    margin-top: -30px;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotate;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  }
  
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotateY(0deg);
    }
    to {
      -webkit-transform: rotateY(360deg);
    }
  }
  
  @-moz-keyframes rotate {
    from {
      -moz-transform: rotateY(0deg);
    }
    to {
      -moz-transform: rotateY(360deg);
    }
  }