.email-card {
  margin: 12px;
  box-shadow: 0 4px 8px 0 rgba(184, 176, 176, 0.2), 0 6px 20px 0 rgba(99, 94, 94, 0.19);
  height: 75px;
  font-weight: 800;
}
.email-card-header {
  background: rgb(214, 231, 244);
}
.download-button .sample-download{
  color: #133B84;
  background: #FFC118; 
  outline: #FFC118;
  border: #FFC118;
}
.email-card-header .col-sm-7 {
  justify-self: flex-end;
}

.table-card .datatable-header, .card-table .datatable-panel {
  all:initial;
  margin-top: 0;
}

.add-user-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer !important;
}

.add-user-wrapper input[type=file] {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.form input.subject + .label-name .content-name,
.form input:valid.subject + .label-name .content-name {
  transform: translateY(-150%);
  font-size: 12px;
  left: 0px;
  color: #0082FF;
}

.download-button .btn-md:hover {
  color: #0082FF;
}

.toggle-button .i {
  align-self: flex-end;
}

.user-modal .modal-content {
  min-height: fit-content;
  max-height: 700px;
}

.form input, .form select, .form textarea .subject {
  width: 100%;
    height: 100%;
    color: #000;
    padding-top: 20px;
    border: none;
    border-bottom: 2px solid #118AFF !important;
}