/* Start side navigation bar  */

.side-nav {
    float: left;
    height: 100%;
    width: 290px; 
    color: #CCC;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all .3s ease-in-out;
    -webkit-transition: .3s;
    transition: .3s;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 9999999;
    /* background: #ffffff; */
    background-size: cover;
    background-repeat: no-repeat;
    background: linear-gradient(180deg,#133B84,#0082FF);
  }
  .side-nav .heading {
      padding: 5px 15px 0px 15px;
      overflow: hidden;
      border-bottom: 1px solid #ffffff;
  }
  .side-nav .heading > a > img {
    float: left;
    width: 18%;
  }
  .side-nav .info {
    float: left;
    width: 69%;
    margin-left: 13%;
    padding: 0px;
  }
  .side-nav .heading .info > h3 {margin: 0 0 5px}
  .side-nav .heading .info > h3 > a {
    color: rgb(255, 255, 255);
    font-weight: 700;
    margin-top: 4px;
    letter-spacing: 1px;
    display: block;
    text-decoration: none;
    font-size: 20px;
    text-align: left;
  }
  .side-nav .heading .info > h3 > a:hover {
    color: #FFF;
    font-weight: bolder;
  }
  .side-nav .heading .info > p {
    color: rgb(250, 250, 250);
    font-size: 13px;
  }
  /* End heading */  
  .side-nav .categories > li {
    padding: 5px 40px 5px 30px;
    overflow: hidden;
    cursor: pointer;
    border-bottom: 1px solid #ffff;
  }
  .side-nav .categories > li > a {
      color: #fff;
      text-decoration: none;
      padding: 10px;
      display: inline-block;
      border-radius: 4px;    
      width: 100%;
  }
    .side-nav .categories > li > a.active {
    background-color: white;
    color: #0082FF;
    font-weight: bold;
  }
  .side-nav .categories > li > a.active {
    background-color: white;
    color: #0082FF;
    font-weight: bold;
  }
  .side-nav .categories > li > a:hover {
    background-color: white;
    color: #0082FF;
    font-weight: bold;
  }
  .side-nav .categories > li > i {
    font-size: 18px;
    margin-right: 5px
  }
  .side-nav .categories > li.drop-down-li > a:after {
    font-size: 11px;
    line-height: 1.8;
    float: right;
    color: #ffffff;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  .side-nav .categories .opend > a:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  /* End categories */
  /* Start dropdown menu */
  .side-nav .categories .side-nav-dropdown {
    padding-top: 10px;
    padding-left: 30px;
    list-style: none;
    /* display: block; */
  }
  .side-nav .categories .side-nav-dropdown > li > a {
    color: #ffff;
    text-decoration: none;
    padding: 7px 0;
    display: block;
  }
  .side-nav .categories .side-nav-dropdown > li > a.active {
    background-color: #cedeeb;
    color: #0870BD;
    border-radius: 4px;
    padding-left: 10px;
  }
  .side-nav .categories p {
      margin-left: 30px;
      color: #ffffff;
      margin-top: 10px;
      text-align: left;
  }
  
  /* End dropdown menu */
  
  .show-side-nav {
    -webkit-transform: translateX(-290px);
    transform: translateX(-290px);
  }
  
  
  /* Start media query */
  @media (max-width: 767px) {
    .side-nav .categories > li {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .side-nav .search {
      padding: 10px 0 10px 30px
    }
  }
  
  /* End side navigation bar  */